<template>
  <div>
    <el-table :data="list">
      <el-table-column prop="id"
                       label="题号">
        <template slot-scope="{row}">
          第{{row.question_num}}题
        </template>
      </el-table-column>
      <el-table-column prop="paper_name"
                       label="阅卷老师">
        <template slot-scope="{row}">
          {{getNames(row)}}
        </template>
      </el-table-column>
      <el-table-column prop="sheet_status"
                       label="操作">
        <template slot-scope="scope">
          <div class="text_btn_primary1"
               @click="checkTeacher(scope.row,scope.$index)">添加老师</div>
        </template>
      </el-table-column>
    </el-table>
    <CheckTeacher ref="checkTeacherRef" />
  </div>
</template>

<script>
import CheckTeacher from './checkTeacher.vue'

export default {
  data () {
    return {
      list: [],
      activeIndex: 0
    }
  },
  components: {
    CheckTeacher
  },
  mounted () {
    this.list = this.$parent.questionNumber
  },
  methods: {
    checkTeacher (row, index) {
      this.activeIndex = index
      let teacherList = this.$parent.teacherList
      this.$refs.checkTeacherRef.allData = teacherList
      this.$refs.checkTeacherRef.list1 = JSON.parse(JSON.stringify(row.edu_user))
      this.$refs.checkTeacherRef.total = teacherList.length
      this.$refs.checkTeacherRef.initData()
      this.$refs.checkTeacherRef.dialogVisible = true

    },
    getNames (row) {
      let str = ''
      row.edu_user.forEach(item => {
        str += item.edu_user_name + ','
      });
      return str.trimRight(",")
    },
    setData (arr) {
      this.list[Number(this.activeIndex)].edu_user = arr
    }
  }
}
</script>

<style lang="scss" scoped>
</style>